<div class="container-fluid">
  <div style="width: 100%;">
    <div>
      <div class="user-role">
        <div role="group" class="row btn-group" *ngIf=" (this.storageService.userProfileData==='users')">
          <div class="col">
            <button class="btn cstm-btn-primary mr-3 mb-2" *ngIf="!toggleRequest" data-toggle="modal"
              data-target="#exampleModal" data-backdrop="false">
              {{"userProfilePage.revokeAll" |cxTranslate}}
            </button>
            <button class="btn cstm-btn-light mr-3 mb-2" *ngIf="!toggleRequest" (click)="editData()">
              {{"userProfilePage.edit"|cxTranslate}}
            </button>
            <button class="btn cstm-btn-light mr-3 mb-2" *ngIf="!toggleRequest" (click)="downloadCSV()">
              <i class="fa fa-download mr-2"></i>
              {{"userProfilePage.csv" |cxTranslate}}
            </button>
            <button class="btn cstm-btn-primary mr-3 mb-2" *ngIf="toggleRequest" (click)="confirmAction('save')">
              {{"userProfilePage.save" |cxTranslate}}
            </button>
            <button class="btn cstm-btn-light mr-3 mb-2" *ngIf="toggleRequest" (click)="cancelData()">
              {{"userProfilePage.cancel" |cxTranslate}}
            </button>
          </div>
        </div>
        <div role="group" class="row btn-group" *ngIf="(this.storageService.userProfileData==='access')">
          <div class="col">
            <button class="btn cstm-btn-primary mr-3 mb-2" (click)="acceptRole()">
              {{"userProfilePage.accept"|cxTranslate}}
            </button>
            <button class="btn cstm-btn-light mr-3 mb-2" (click)="rejectRole()">
              {{"userProfilePage.reject"|cxTranslate}}
            </button>
            <button class="btn cstm-btn-light mr-3 mb-2" (click)="goBack()">
              {{"userProfilePage.back"|cxTranslate}}
            </button>
          </div>
        </div>
        <div *ngIf="cartsListPending?.comment" class="row mt-3">
          <div class="col-4">
            <label class="labeltext">{{"userProfilePage.comment"|cxTranslate}}</label>
            <p>{{cartsListPending.comment}}</p>
          </div>
        </div>
        <div *ngIf="!internal">
          <app-table-template [columns]="columns" [tableData]="cartsList" [isEdit]="isShowCheckBox"
            [allowPagination]="true" [rowsPerPage]="10" (checkBtnClickEvent)="updateCheckBtnClick($event)"
            (filteredData)="onFilter($event)"></app-table-template>
        </div>
        <div *ngIf="internal">
          <button class="btn cstm-btn-light disableToggle" style="margin-left: 1042px;margin-top: -68px;"
            [ngClass]="{'toggle-disable':revokAllFlag==false && !userProfileService.disableThreeButton}">
            <div style="margin-top: -30px;">
              {{"userProfilePage.admin" |cxTranslate}} <label class="switch">
                <input type="checkbox" [checked]="adminToggleFlag" (click)="toggleChangevalue()"
                  [disabled]="revokAllFlag==false && !userProfileService.disableThreeButton" id="toggleCheck">
                <span class="slider round" style="margin-top: 29px;
              margin-bottom: -13px;"></span>
              </label>
            </div>
          </button>
          <app-table-template [columns]="columns1" [tableData]="cartsList" [isEdit]="isShowCheckBox"
            [allowPagination]="true" [rowsPerPage]="10" (checkBtnClickEvent)="updateCheckBtnClick($event)"
            (filteredData)="onFilter($event)"></app-table-template>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(role ==='SGRE Admin') && isShowCheckBox">
    <div class="form-row" *ngIf='revokAllFlag'>
      <div class="col-4">
        <p-dropdown styleClass="cstm-dropdown" [filter]="true" [options]="entityList" optionLabel="name"
          placeholder=" {{'userProfilePage.selectAnEntity' |cxTranslate}}"
          emptyMessage="{{'userProfilePage.noMatchingOptions' |cxTranslate}}" [(ngModel)]="selectedCity" />
      </div>
      <div class="col-2">
        <button type="button" class="btn cstm-btn-primary ml-3" (click)="addNewEntryToTable()">
          {{"userProfilePage.add" |cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{"userProfilePage.revokeAll" |cxTranslate}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label>{{"userProfilePage.actionWillRemoveAccess" |cxTranslate}}</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn cstm-btn-light" data-dismiss="modal">{{"userProfilePage.close"
          |cxTranslate}}</button>
        <button class="btn cstm-btn-primary" data-dismiss="modal" (click)="confirmAction('revoke')"
          data-dismiss="modal">{{"userProfilePage.confirm" |cxTranslate}}</button>
      </div>
    </div>
  </div>
</div>